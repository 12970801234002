<template>
  <div class="btn-toolbar" role="toolbar">
    <div class="btn-group shadow-sm me-2" role="group">
      <button type="button" class="btn btn-sm btn-light" :class="{ active: theme == 'light' }" @click="$emit('update:theme', 'light')"><font-awesome-icon icon="sun" /></button>
      <button type="button" class="btn btn-sm btn-light" :class="{ active: theme == 'dark' }" @click="$emit('update:theme', 'dark')"><font-awesome-icon icon="moon" /></button>
    </div>

    <div class="btn-group shadow-sm" role="group">
      <button type="button" class="btn btn-sm btn-light" :class="{ active: projection == 'globe' }" @click="$emit('update:projection', 'globe')"><font-awesome-icon icon="globe" /></button>
      <button type="button" class="btn btn-sm btn-light" :class="{ active: projection == 'mercator' }" @click="$emit('update:projection', 'mercator')"><font-awesome-icon icon="map" /></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'light'
    },

    projection: {
      type: String,
      default: 'globe'
    }
  }
}
</script>
