import { createApp } from 'vue'
import App from '@/Live.vue'

import { apolloProvider } from '@/core/apollo'
import { FontAwesomeIcon } from '@/core/fontawesome'

const app = createApp(App)
app.use(apolloProvider)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
