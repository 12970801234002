<template>
  <Map ref="map" v-model:zoom="zoom" :theme="theme" :projection="projection">
    <BirdWeatherLiveDetectionsMapLayer />

    <SpeciesMarkers />

    <MapOptions v-model:theme="theme" v-model:projection="projection" class="map-absolute top right" />
  </Map>
</template>

<script>
import BirdWeatherLiveDetectionsMapLayer from '@/components/layers/BirdWeatherLiveDetectionsMapLayer.vue'
import MapOptions from '@/components/controls/MapOptions.vue'

import SpeciesMarkers from '@/components/behaviors/SpeciesMarkers.vue'

import Map from '@/components/Map.vue'

export default {
  data() {
    return {
      zoom: 6,
      originalZoom: null,
      theme: 'light',
      projection: 'mercator'
    }
  },

  components: {
    BirdWeatherLiveDetectionsMapLayer,
    Map,
    MapOptions,
    SpeciesMarkers
  }
}
</script>
